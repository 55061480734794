import { LitElement, html, css, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js'

@customElement('ctx-hero')
export class CtxHero extends LitElement {
  static styles = [
    css`
      :host {
        display: block;
        
       /* padding: 1em;*/

        /*background-color:#dbeeff;*/
        box-shadow:0px 10px 25px -10px rgba(0,0,0,0.52);

        
        padding: clamp(1em, 5%, 6em);
      }

      :host([short]){
        padding-block: 0;
      }

      .grid{
        display:grid;
        grid-template-columns:1fr 1fr;
        gap:1em;
      }

      .left {
        display:flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap:0.8em;
      }

      .title {
        font-size:2em;
        font-weight:700;
      }

      .cta{
        
      }
      .cta fluent-button  {
        border-radius:0;
        --control-corner-radius:0;
        /*box-shadow: 5px 10px;*/
        box-shadow:0px 10px 25px -10px rgba(0,0,0,0.52);
      }

      img{
        display: block;
        width: 100%; /* or any custom size */
    /*height: 100%; 
    object-fit: contain;*/
      }

      .image{
        align-self: center;
        justify-self: center;
      }

      a {
        //just to make it hidden
        display:contents
      }
    `
  ];

@property({ type: String }) title: string;
@property({ type: String }) description: string;
@property({ type: String, attribute:"call-to-action" }) callToAction: string;
@property({ type: String, attribute:"call-to-action-url" }) callToActionUrl: string;
@property({ type: String, attribute:"src" }) src: string;

@property({ type: Boolean, attribute:"short" }) short:boolean=false;

  render() {
    return html`
    
    <div class="grid">
      <div class="left">
        <div class="title">${this.title}</div>
        <div class="description">
          <slot name="description">
            <slot>
              ${this.description}
            </slot>
          </slot>
        </div>
        <slot name="cta">
          ${this.callToActionUrl != null ?  html`
          <div class="cta">
            <a href="${this.callToActionUrl}" >
              <fluent-button type="button" appearance="outline">${this.callToAction}</fluent-button>
            </a>
          </div>
          `:nothing}  
        </slot>
      </div>
      
      <div class="image">
        <slot name="image">
          <img src=${this.src}>
        </slot>
      </div>

    </div>
    
    
    `;
  }
}

