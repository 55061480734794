import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js'
import { Task } from '@lit/task';
import { services } from "src/services";
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { raiseFetchResponseError } from 'src/modules/helpers/response-helpers';

@customElement('ctx-content-preview')
export class CtxContentPreview extends LitElement {
  createRenderRoot() {
    return this;
  }

  @property({ type: Boolean }) grow: boolean = false;
  @property({ type: Boolean }) scrollToCursor: boolean = true;
  @property({ type: Number }) cursorPosition: number;

  @state() private _value: string = "";
  @state() htmlContent: string = "";

  get value(): string {
    return this._value;
  }

  set value(newValue: string) {
    this._value = newValue;
    this.requestUpdate();
  }

  private _markdownPreviewTask: Task = new Task(this, {
    task: async ([value], { signal }) => {
      var response = await services.management.markdown.preview(value).catch(raiseFetchResponseError);
      if(signal.aborted)
      {
        return this._value;
      }
      this.htmlContent = response.markup;
      return response.markup;
    },
    args: () => [this._value],
  });

  private _scrollToCursor() {
    if (this.scrollToCursor) {
      const bottomOffset = 100; // We want to check if the cursor is within the last 100 characters.
      const contentLength = this.value.length;
      let bottomPosition = contentLength <= bottomOffset ? contentLength : contentLength - bottomOffset;

      // If the cursor position is withing the last 100 characters of the content, add a scroll marker.
      if (this.cursorPosition > bottomPosition) {
        this.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
  }

  // This component is rendered using the light DOM to ensure the markdown being previewed renders as expected.
  // So styles are added to the render method to get it applied.
  render() {
    return html`
    <style>
      .ctx-content-preview {
        position: relative;
        max-height: 200px;
      }
    </style>
    ${ this.grow ? this._renderPerview() : html`<div class="ctx-content-preview"> ${ this._renderPerview() }</div>` }`;
  }

  private _renderPerview() {
    return html`${this._markdownPreviewTask.render({
      initial: () => html``,
      pending: () => unsafeHTML(this.htmlContent),
      complete: () => html`${unsafeHTML(this.htmlContent)}${this._scrollToCursor()}`,
      error: (error) => html`<p>Oops, something went wrong: ${error}</p>`,
      })}`;
  }
}