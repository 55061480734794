import { LitElement, html, css } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js'
import { EditorContent } from './ctx-editor-content-event';
import { SnippetInsertValue, SnippetWrapValue } from './ctx-snippet-types';

@customElement('ctx-content-textarea')
export class CtxContentTextArea extends LitElement {
  static styles = [
    css`
        textarea {
            border: 0.5px solid #d1d5db;
            box-sizing: border-box;
            height: 100%;
            max-height: 100%;
            min-height: 200px;
            overflow-y: auto;
            padding: 0.5rem;
            width: 100%;
        }
    `
  ];

  @query("#editor") editor: HTMLTextAreaElement;

  @property({ type: Boolean }) readonly: boolean = false;

  @property({attribute:"value"}) private initialValue: string = '';

  handleContentChange(content: EditorContent) {
    if (content.behaviour === "insert") {
      this._insertContent(content.value as SnippetInsertValue);
    }

    if (content.behaviour === "wrap") {
      this._wrapSelectedContent(content.value as SnippetWrapValue);
    }
  }

  private _contentChanged = async (event: InputEvent) => {
    this.#raiseContentChanged();
  }

  #raiseContentChanged = () => {
    this.dispatchEvent(new CustomEvent("content-changed", { detail:  {
      content: this.editor.value,
      cursorPosition: this.editor.selectionStart
    }, bubbles:true, composed:true }));
  }

  private _insertContent(content: string | SnippetInsertValue) {
    var contentToInsert = typeof content === 'string' ? content : content.snippet; 
    this.editor.setRangeText(contentToInsert, this.editor.selectionStart, this.editor.selectionEnd, "preserve");
    this.editor.setSelectionRange(this.editor.selectionStart, this.editor.selectionStart + contentToInsert.length)
    this.editor.focus();
    this.#raiseContentChanged();
  }


  private _wrapSelectedContent(value: SnippetWrapValue) {
    const start = this.editor.selectionStart;
    const end = this.editor.selectionEnd;

    const selectedContent = this.editor.value.slice(start, end);
    const wrappedContent = `${value.pre}${selectedContent}${value.post}`;

    this.editor.setRangeText(wrappedContent, start, end, "preserve");
    this.#raiseContentChanged();
  }

  render() {
    return html`<textarea ?readonly=${this.readonly} id="editor" class="component" @input=${this._contentChanged}>${this.initialValue}</textarea>`;
  }
}