import { LitElement, html, css, nothing } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js';
import { CtxContentTextArea } from './ctx-content-textarea';
import { EditorContentEvent } from './ctx-editor-content-event';
import { CtxContentPreview } from './ctx-content-preview';

export type PreviewPosition = "right" | "below";

@customElement('ctx-content-editor')
export class CtxContentEditor extends LitElement {
  static styles = [
    css`
        :host {
          position: relative;
          height: 100%;
        }

        .content-editor {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          height: 100%;
          padding: 0;
          position: relative;
        }

        .preview {
            min-height: 200px;
            overflow-y: auto;
            position: relative;
            padding: 0.5rem;
            width: auto;
        }

        .preview-below {
          display: block;
        }

        .preview-right {
          display: grid;
          grid-template-columns: calc(50% - 4px) calc(50% - 4px); /* To account for the gap */
          gap: 8px;
          height: 100%;
        }

        .slot {
          width: 100%;
          padding: 0;
        }

        .textarea-and-preview {
          flex-grow: 1;
          height: 100%;
        }

        .top-slot {
          position: sticky;
          top: 0;
          z-index: 999;
        }
    `
  ];

  static formAssociated = true;

  @property({ type: Boolean }) readonly: boolean = false;
  @property({ type: Boolean }) showPreview: boolean = true;
  @property({ type: String }) previewPosition: string = "bottom";

  @query("#text-area") textArea: CtxContentTextArea;

  @state() private _preview: CtxContentPreview;

  @state() private _value: string = '';

  get value(): string {
    return this._value;
  }

  set value(newValue: string) {
    this._value = newValue;

    this._internals.setFormValue(newValue);
    this.requestUpdate();
  }

  private _internals: ElementInternals;

  private _handlePreviewSlotChange(e: Event) {
    const slot = e.target  as HTMLSlotElement;
    const slottedElements = slot.assignedElements();
    
    slottedElements.forEach(element => {
      if (element instanceof HTMLElement && element.tagName.toLowerCase() === 'ctx-content-preview') {
        this._preview = element as CtxContentPreview;
        this._preview.scrollToCursor = this.previewPosition !== "bottom";
        this._setPreviewValueAndCursorPosition(this.value);
      }
    });
  }

  private _handleContentFromToolbar = async (event: EditorContentEvent) => {
    this.textArea.handleContentChange(event.content);
  }

  private _setPreviewValueAndCursorPosition(value: string, cursorPosition: number = 0) {
    if (this._preview) {
      this._preview.cursorPosition = cursorPosition;
      this._preview.value = value;
    }
  }

  private _textAreaContentChanged = async (event: CustomEvent<{cursorPosition:number, content:string}>) => {
    var content = event.detail.content;
    this._internals.setFormValue(content);
    this._setPreviewValueAndCursorPosition(content, event.detail.cursorPosition);
  }

  constructor() {
    super();
    this._internals = this.attachInternals();
    this.addEventListener('handle-editor-content', this._handleContentFromToolbar);
  }

  connectedCallback() {
    super.connectedCallback();
    if (this.hasAttribute('value')) {
      this.value = this.getAttribute('value') || '';
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('handle-editor-content', this._handleContentFromToolbar);
  }

  render() {
    return html`<div class="content-editor" style="--dc-edit-mode:true">
        <div class="top-slot">
          <slot name="top"></slot>
        </div>
        <div class="textarea-and-preview">
          ${this._renderTextAreaAndPreviewSlot()}
        </div>
    </div>`;
  }

  private _renderBottomSlot() {
    return html`<slot name="bottom" class="slot"></slot>`;
  }

  private _renderTextArea() {
    return html`<ctx-content-textarea id="text-area" value=${this.value} @content-changed=${this._textAreaContentChanged}></ctx-content-textarea>`; 
  }

  private _renderTextAreaAndPreviewSlot() {
    const classes = { 'preview-below': this.previewPosition === 'below', 'preview-right': this.previewPosition === 'right' };
    return html`<div class=${classMap(classes)}>
      <div>
        ${ this._renderTextArea() }
        ${ this._renderBottomSlot() }
      </div>
      ${ this._renderPreviewSlot() }
    </div>`;
  }

  private _renderPreviewSlot() {
    return this.showPreview ? html`<div class="preview">
      <slot @slotchange=${this._handlePreviewSlotChange}></slot>
    </div>` : nothing;
  }
}